<ng-container *ngIf="client$ | async as client">
  <div class="app-header-workflow d-flex justify-content-left align-items-center" [ngClass]="{'with-client-logo':clientLogoUrl}">
    <a class="tasc-logo-link" routerLink="/dashboard" data-cy="nav-dashboard-link">
      <img src="assets/images/{{brand.logoFileName}}" [alt]="brand.companyName">
    </a>
    <span *ngIf="clientLogoUrl" class="client-logo-container">
      <img [src]="clientLogoUrl | safeUrl" [alt]="client.name">
    </span>
  </div>
</ng-container>
