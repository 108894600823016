// AUTOGENERATED BY FUNCTION genubamodel.js
export interface IdentityProfileCommand extends CommandBase {
  data?: IdentityProfile;
}

export interface GroupPermissionCommand extends CommandBase {
  data?: GroupPermission;
}

export interface PermissionOptionsCommand extends CommandBase {
  data?: PermissionOptions;
}

export interface TokenCommand extends CommandBase {
  data?: Token;
}

export interface UserPermissionCommand extends CommandBase {
  data?: UserPermission;
}

export interface GroupPermission extends EntityBase {
  allowedActions?: AllowedActions;
  app?: string;
}

export interface IdentityProfile extends EntityBase {
  identityId?: string;
  profileType?: string;
  profileId?: string;
  idpGroup?: string;
  selectedClientId?: string;
}

export interface UserPermission extends EntityBase {
  sub?: string;
  allowedActions?: string;
  data?: string;
}

export interface Permission {
  name?: string;
  type?: string;
  path?: string;
  selected?: boolean;
}

export interface PermissionOptions extends EntityBase {
  sections?: PermissionSection[];
}

export interface PermissionSection {
  title?: string;
  id?: string;
  selectAll?: boolean;
  permissionSubjects?: PermissionSubject[];
}

export interface PermissionSubject {
  title?: string;
  selected?: boolean;
  permissions?: Permission[];
}

export interface Token extends EntityBase {
  token?: string;
  srtPlanId?: string;
}

export interface AllowedActions {
  excludedClients: ExcludedClient[];
  sections: Section[];
  allowedFundingTransferTypes: FundsTransferType[];
}

export interface ExcludedClient {
  id: string;
  name?: string;
}

export interface Section {
  id?: string;
  queryPaths: string[];
  commandPaths?: CommandPath[];
}

export interface CommandPath {
  commands: string[];
  path: string;
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface QueryCriteria {
  index?: string;
  searchCriteria: SearchCriteria;
  filterCriteria?: SearchCriteria;
  projectionExpression?: string;
}

export type SearchCriteria = Criteria[];

export type GroupPermissions = GroupPermission[];

export type IdentityProfiles = IdentityProfile[];

export type UserPermissions = UserPermission[];

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum UserPermissionCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum IdentityProfileCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum GroupPermissionCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum PermissionOptionsCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum FundsTransferType {
  RemittanceDistributionToClient = 'RemittanceDistributionToClient',
  MyCashToPaymentSource = 'MyCashToPaymentSource',
  BenefitAccountToMyCashWithoutRequest = 'BenefitAccountToMyCashWithoutRequest',
  TakeoverBenefitAccountToMyCashWithoutRequest = 'TakeoverBenefitAccountToMyCashWithoutRequest',
  MyCashToCharity = 'MyCashToCharity',
  GivebackAccountToCharity = 'GivebackAccountToCharity',
  ParticipantPaymentSourceToBenefitAccount = 'ParticipantPaymentSourceToBenefitAccount',
  ParticipantPaymentSourceToInvestmentBenefitAccount = 'ParticipantPaymentSourceToInvestmentBenefitAccount',
  ClientPaymentSourceToBenefitAccount = 'ClientPaymentSourceToBenefitAccount',
  ClientPaymentSourceToInvestmentBenefitAccount = 'ClientPaymentSourceToInvestmentBenefitAccount',
  MyCashToBenefitAccount = 'MyCashToBenefitAccount',
  FundedConversionRolloverToBenefitAccount = 'FundedConversionRolloverToBenefitAccount',
  ParticipantPaymentSourceToMyCash = 'ParticipantPaymentSourceToMyCash',
  MyCashToBenefitAccountCardRefund = 'MyCashToBenefitAccountCardRefund',
  UnfundedConversionRolloverToBenefitAccount = 'UnfundedConversionRolloverToBenefitAccount',
  ParticipantPaymentSourceToBenefitAccountRefund = 'ParticipantPaymentSourceToBenefitAccountRefund',
  PaymentSourceToCobraBenefitAccount = 'PaymentSourceToCobraBenefitAccount',
  CustodialInvestmentRolloverToBenefitAccount = 'CustodialInvestmentRolloverToBenefitAccount',
  ParticipantInvestmentRolloverToBenefitAccount = 'ParticipantInvestmentRolloverToBenefitAccount',
  MyCashAdjustment = 'MyCashAdjustment',
  InvestmentBenefitAccountAdjustment = 'InvestmentBenefitAccountAdjustment',
  BenefitAccountAdjustment = 'BenefitAccountAdjustment',
  UnfundedBenefitAccountAdjustment = 'UnfundedBenefitAccountAdjustment',
  PaymentPlanToPayee = 'PaymentPlanToPayee',
  MyCashToCobraBenefitAccount = 'MyCashToCobraBenefitAccount',
  PptCheckToPptHoldingAccount = 'PptCheckToPptHoldingAccount',
  PptACHToPptHoldingAccount = 'PptACHToPptHoldingAccount',
  PptCardToPptHoldingAccount = 'PptCardToPptHoldingAccount',
  PptUndesignatedHoldingAccountToPptHoldingAccount = 'PptUndesignatedHoldingAccountToPptHoldingAccount',
  PptUndesignatedHoldingAccountToPptHoldingAccountPending = 'PptUndesignatedHoldingAccountToPptHoldingAccountPending',
  PptDesignatedHoldingAccountToPptHoldingAccount = 'PptDesignatedHoldingAccountToPptHoldingAccount',
  PptDesignatedHoldingAccountInactivation = 'PptDesignatedHoldingAccountInactivation',
  PptUndesignatedHoldingAccountInactivation = 'PptUndesignatedHoldingAccountInactivation',
  MyCashFeeSweepAutomated = 'MyCashFeeSweepAutomated',
  MyCashFeeSweepManual = 'MyCashFeeSweepManual',
  HSAOrphanFeeSweepAutomated = 'HSAOrphanFeeSweepAutomated',
  HSAOrphanFeeSweepManual = 'HSAOrphanFeeSweepManual',
  ClientFeeAssessmentSweepAutomated = 'ClientFeeAssessmentSweepAutomated',
  ClientFeeAssessmentSweepManual = 'ClientFeeAssessmentSweepManual',
  ACHConvenienceFeeSweepAutomated = 'ACHConvenienceFeeSweepAutomated',
  ACHConvenienceFeeSweepManual = 'ACHConvenienceFeeSweepManual',
  CardConvenienceFeeSweepAutomated = 'CardConvenienceFeeSweepAutomated',
  CardConvenienceFeeSweepManual = 'CardConvenienceFeeSweepManual',
  PaymentPlanAdminFeeSweepAutomated = 'PaymentPlanAdminFeeSweepAutomated',
  PaymentPlanAdminFeeSweepManual = 'PaymentPlanAdminFeeSweepManual',
  RemittanceDistributionToPayee = 'RemittanceDistributionToPayee',
  UndesignatedHoldingAccountAdjustment = 'UndesignatedHoldingAccountAdjustment',
  DesignatedHoldingAccountAdjustment = 'DesignatedHoldingAccountAdjustment',
  RemittanceStagingAccountAdjustment = 'RemittanceStagingAccountAdjustment',
  ClientRemittanceAccountAdjustment = 'ClientRemittanceAccountAdjustment',
  PayeeRemittanceAccountAdjustment = 'PayeeRemittanceAccountAdjustment',
  MyCashUndesignatedHoldingAccountAdjustment = 'MyCashUndesignatedHoldingAccountAdjustment',
  MyCashDesignatedHoldingAccountAdjustment = 'MyCashDesignatedHoldingAccountAdjustment',
  SweepPaymentProcessorBinAccount = 'SweepPaymentProcessorBinAccount',
  SweepBinPayeeAccount = 'SweepBinPayeeAccount',
}

export enum TokenCommandType {
  authorize = 'authorize',
  create = 'create',
  inject = 'inject',
  refresh = 'refresh',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum PermissionLevel {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
}

export enum ParentType {
  INSTANCE = 'INSTANCE',
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  REQUEST = 'REQUEST',
}
