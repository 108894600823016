<app-header-workflow></app-header-workflow>
<div class="bg-img-password">
  <main class="container">
    <div class="line-bg">
      <div class="d-lg-flex justify-content-between">
        <div class="title text-uppercase">Create a new password
          <div class="separator mt-3 d-block"></div>
        </div>
      </div>
      <div class="password-contain">
        <form name="newPasswordForm" [formGroup]="newPasswordForm">
          <div class="password-content row box-shadow-container mt-1">
            <div class="col-12 d-flex justify-content-end">
              <button type="button" (click)="cancelModal.open()" class="no-border-btn font-24 text-green-color font-light"
                data-cy="request-cancel">Cancel</button>
            </div>
            <div name="incurredBy" class="password-container p-3">
              <div class="title">
                Please enter a new password.
              </div>
              <div class="font-reguler graylight mt-3 mb-5">You will not be able to access {{brand.productName}} until your password is updated.</div>
              <app-password-textbox label="New password" [allowShowPassword]="true">
                <input type="password" class="form-control" formControlName="password" />
              </app-password-textbox>
              <app-password-rules [validateForm]="newPasswordForm"></app-password-rules>
            </div>
          </div>
          <div class="btn-group1 d-lg-flex justify-content-end align-items-center">
            <button appDisableAfterClick class="btn btn-primary px-5 ml-lg-5" type="button" id="newPasswordSubmit"
              [disabled]="!newPasswordForm.valid" (click)="onSubmit()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </main>
</div>

<div class="footer">
  <app-footer></app-footer>
</div>

<app-modal size="tiny" #cancelModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title title">Are you sure you want to cancel creating a new password?</h5>
    </div>
    <div class="modal-body">
      You will not be able to access {{brand.productName}} until your password is updated.
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="cancelModal.close()">No</button>
      <button type="button" class="btn btn-primary" routerLink="/logout">Yes</button>
    </div>
  </div>
</app-modal>
