<app-modal size="small" #modal data-cy="app-federated-redirect-modal">
  <div class="modal-content text-center">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="setUserAuthenticationChallenge(AuthenticationChallengeType.Unknown)">
        <i class="icon icon-close" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <h1 class="font-light weight mb-4 default lh-40">Access to your {{brand.benefitText}}s is established through your employer portal.</h1>
    </div>
    <div class="pt-3 modal-footer">
      <div class="d-flex col-sm-12 col-xs-12 px-0">
        <button name="employeePortal" type="button" (click)="redirectToFederatedSignInPage()"
          class="btn btn-block my-1 btn-primary">{{ buttonLabel }}</button>
      </div>
      <div class="d-flex col-sm-12 col-xs-12 px-0 mt-3">
        <button name="passwordAuthenticate" type="button" aria-label="Close" (click)="setUserAuthenticationChallenge(AuthenticationChallengeType.Password)"
          class="btn btn-block my-1 btn-outline-secondary" data-cy="continue-with-password">Continue with my own password</button>
      </div>
    </div>
  </div>
</app-modal>
