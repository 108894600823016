import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    MatProgressSpinnerModule,
  ],
  exports: [
    MatProgressSpinnerModule,
  ],
  declarations: [],
})
export class MaterialSharedModule { }
