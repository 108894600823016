import { BrandResource } from 'src/app/shared/models/pux/model';

export const tascResources: BrandResource = {
  generalAccountText: 'account',
  generalPlanText: 'plan',
  benefitAccountText: 'benefit account',
  benefitPlanText: 'benefit plan',
  benefitText: 'benefit',
  cardLegalText: 'This Mastercard is administered by TASC, a registered agent of Pathward®, N.A. Use of this card is authorized as set forth in your Cardholder Agreement. The TASC Card is issued by Pathward, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.',
  cardLink: 'TASC Wallet',
  companyCard: 'TASC card',
  companyFullName: 'Total Administrative Services Corporation',
  companyName: 'TASC',
  companyNameCopyright: 'Total Administrative Services Corporation (TASC)',
  contactHours: 'Monday - Friday, 8a.m. - 5p.m.<br>based on your timezone.',
  brandCssClass: 'brand-tasc',
  faviconFileName: 'favicon-tasc.ico',
  hideWalletAvailableBalance: true,
  logoFileName: 'logo-tasc.png',
  participantId: 'TASC ID',
  phoneNumber: '800-422-4661',
  productName: 'Universal Benefit Account',
  requestCompleteImage1FileName: 'rfr-complete-1-tasc.png',
  requestCompleteImage3FileName: 'rfr-complete-3-tasc.png',
  termsOfUseFileName: 'terms-tasc.html',
};
