export enum PageTitle {
    ALL_BENEFITS = 'Accounts Summary',
    BANK_ACCOUNT = 'Bank Accounts',
    DEPENDENTS = 'Dependents',
    LOGIN = 'Employee Sign In',
    MY_CASH = 'MyCash Transfer',
    OVERVIEW = 'Accounts Overview',
    PAY_A_PROVIDER = 'Pay A Provider',
    PAY_PROVIDER_DETAILS = 'Pay Provider Details',
    PROFILE = 'Profile',
    REIMBURSEMENT_DETAILS = 'Reimbursement Details',
    REIMBURSEMENT_HISTORY = 'Reimbursement History',
    REIMBURSEMENT_REQUEST = 'Reimbursement Request',
    SECURITY = 'Security',
    SIGN_UP = 'Account Sign Up',
    TRANSACTIONS = 'Account Transactions',
    INVESTMENTS = 'Investment Activity',
}
