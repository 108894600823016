import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit {
  @ViewChild(ModalDirective, { static: true }) public self: ModalDirective;
  @Input() public backdrop: boolean | 'static' = true;
  @Input() public size: 'tiny' | 'small' | 'medium' | 'large' = 'medium';
  @Input()
  public get isOpen(): boolean {
    return this._isOpen;
  }
  public set isOpen(value: boolean) {
    const wasOpen = this._isOpen;
    this._isOpen = value;
    if (this._isOpen) {
      this.openModal();
    } else {
      this.closeModal(wasOpen);
    }
  }
  @Output() public modalOpen = new EventEmitter<void>();
  @Output() public modalClose = new EventEmitter<void>();
  private _isOpen = false;
  private isViewInitialized = false;

  public constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    this.isViewInitialized = true;
    if (this.isOpen) {
      this.openModal();
    }
  }

  public open(): void {
    this.isOpen = true;
    this.changeDetectorRef.markForCheck();
  }

  public close(): void {
    this.isOpen = false;
    this.changeDetectorRef.markForCheck();
  }

  private openModal(): void {
    if (this.isViewInitialized && this.self && !this.self.isShown) {
      this.self.show();
      this.modalOpen.emit();
    }
  }

  private closeModal(wasOpen: boolean): void {
    if (this.isViewInitialized) {
      if (this.self && this.self.isShown) {
        this.self.hide();
      }
      if (wasOpen) {
        this.modalClose.emit();
      }
    }
  }
}
