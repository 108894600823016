<div class="form-group input-group password-instruction my-0" [ngClass]="{'show-inst': validateForm.controls.password.value}">
  <ul class="d-flex flex-column text-left mx-0 px-0">
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.minlength) }" class="py-1" data-cy="password-validation-eight-characters">
      <span [ngClass]="validateForm?.controls?.password?.errors?.minlength ? 'icon-process mr-3' : 'icon-check mr-3'"></span> At least 8 characters
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresUppercaseCharacter) }" class="py-1" data-cy="password-validation-one-upper">
      <span [ngClass]="validateForm?.controls?.password?.errors?.requiresUppercaseCharacter ? 'icon-process mr-3' : 'icon-check mr-3'"></span> At least one uppercase letter
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresLowercaseCharacter) }" class="py-1" data-cy="password-validation-one-lower">
      <span [ngClass]="validateForm?.controls?.password?.errors?.requiresLowercaseCharacter ? 'icon-process mr-3' : 'icon-check mr-3'"></span> At least one lowercase letter
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresSpecialCharacter) }" class="py-1" data-cy="password-validation-one-special">
      <span [ngClass]="validateForm?.controls?.password?.errors?.requiresSpecialCharacter ? 'icon-process mr-3' : 'icon-check mr-3'"></span> At least one special character (!@#$%)
    </li>
    <li [ngClass]="{ 'password-valid': !(validateForm?.controls?.password?.errors?.requiresNumericCharacter) }" class="py-1" data-cy="password-validation-one-numeric">
      <span [ngClass]="validateForm?.controls?.password?.errors?.requiresNumericCharacter ? 'icon-process mr-3' : 'icon-check mr-3'"></span> At least one numeric
    </li>
  </ul>
</div>
