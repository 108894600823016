// AUTOGENERATED BY FUNCTION genubamodel.js
export interface BureauCommand extends CommandBase {
  data?: Bureau;
}

export interface PayeeCommand extends CommandBase {
  data?: Payee;
  contacts?: Contacts;
  bankAccounts?: BankAccounts;
}

export interface ClientCommand extends CommandBase {
  data?: Client;
  args?: ClientArgs;
}

export interface DistributorCommand extends CommandBase {
  data?: Distributor;
}

export interface ContactCommand extends CommandBase {
  data?: Contact;
}

export interface DependentCommand extends CommandBase {
  data?: Dependent;
}

export interface FinancialInstitutionCommand extends CommandBase {
  data?: FinancialInstitution;
}

export interface IndividualCommand extends CommandBase {
  data?: Individual;
  args?: IndividualArgs;
}

export interface InstanceCommand extends CommandBase {
  data?: Instance;
}

export interface CharityCommand extends CommandBase {
  data?: Charity;
}

export interface ForgotPasswordRequestCommand extends CommandBase {
  data?: ForgotPasswordRequest;
}

export interface ClientIdentityProviderCommand extends CommandBase {
  data?: ClientIdentityProvider;
}

export interface ClientHoldAuditCommand extends CommandBase {
  data?: ClientHoldAudit;
}

export interface EligibilityEventProfileCommand extends CommandBase {
  data?: EligibilityEventProfile;
}

export interface Address {
  id?: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country: string;
  zip?: string;
  zipplusfour?: string;
}

export interface PlanActions {
  planId: string;
  canDelete: boolean;
  canCopy: boolean;
  canRenew: boolean;
}

export interface BankAccount extends EntityBase {
  name?: string;
  accountType?: BankAccountType;
  bankName?: string;
  routingNumber?: string;
  accountNumber?: string;
  paymentAccountType: PaymentAccountType;
  cardType?: CardType;
  token?: string;
  lastFour?: string;
  expirationDate?: string;
  address?: Address;
  addressOwnerType?: AddressOwnerType;
  paymentOwnerName?: string;
  useForFees?: boolean;
  useForFunding?: boolean;
  investmentTradingPartnerId?: string;
  investmentIndividualId?: string;
  investmentPlanId?: string;
  paymentVendor?: PaymentVendor;
}

export interface Bureau extends EntityBase {
  name: string;
  isPrimary: boolean;
}

export interface Payee extends EntityBase {
  name: string;
  address: Address;
  payeeType: PayeeType;
  remittancePaymentMethodAccountId?: string;
}

export interface Contact extends EntityBase {
  clientId: string;
  tascId?: string;
  contactType?: ContactType;
  position?: PositionType;
  businessFunction?: BusinessFunctionType[];
  fileType?: FileType[];
  firstName: string;
  middleName?: string;
  lastName: string;
  companyName?: string;
  email: string;
  phone: string;
  ext?: string;
  phoneType: PhoneType;
  title?: string;
  userAccessRoles?: UserAccessRoleType[];
  primaryContact?: boolean;
  accountOfferingIds?: string[];
  lastFourOfSSN?: string;
  pwResetAttemptCounter?: number;
  payeeId?: string;
  allowClientAccess: boolean;
  fax?: string;
  contactRoles?: ContactRoleType[];
  contactRoleOther?: string;
}

export interface Client extends EntityBase {
  name: string;
  externalId?: string;
  ein?: string;
  depletionOrder?: string[];
  filingStatus?: FilingStatusType;
  otherFilingStatus?: string;
  allowCard: boolean;
  allowDisbursement?: boolean;
  offerAdvanceAccount?: boolean;
  maxAdvanceAmount?: number;
  addresses?: Addresses;
  primaryAddress?: string;
  cardType?: ClientCardType;
  cardMailingOption?: CardMailingOptionType;
  cardSecondLine?: CardSecondLineType;
  allowEligibilityClasses?: boolean;
  waitingPeriod?: number;
  eligibilityEffectiveDate?: EligibilityEffectiveDateType;
  eligibilityClasses?: EligibilityClasses;
  advanceFeatureId?: string;
  cashFeatureId?: string;
  cardPackageId?: string;
  cancellationReason?: CancellationReasonType;
  requestedCancellationDate?: string;
  cancellationProcessedDate?: string;
  customizationPackage?: CustomizationPackageType;
  cardFeePaidBy: CardFeePaidByType;
  naicsCode: string;
  multiEmployerType: boolean;
  employerType?: EmployerType;
  relatedEntities?: string;
  sendToClient: boolean;
  sendToParticipants: boolean;
  fundMidyearImmediately?: boolean;
  manualHold?: boolean;
  manualHoldReason?: ManualHoldReasons;
  manualHoldNote?: string;
  systemHold?: boolean;
  systemHoldReason?: SystemHoldReasons;
  holdException?: boolean;
  holdExceptionReason?: HoldExceptionReasons;
  holdExceptionEndDate?: string;
  holdExceptionNote?: string;
  tpaPartner?: TPAPartnerType;
  digitalCardOnly?: boolean;
  communicationPackage?: CommunicationPackageType[];
  netsuiteId?: string;
}

export interface Distributor extends EntityBase {
  name: string;
}

export interface ClientHoldAudit extends EntityBase {
  activity: ClientHoldAuditActivity;
  reason?: ClientHoldAuditReason;
  exceptionExpirationDate?: string;
}

export interface Dependent extends EntityBase {
  firstName: string;
  middleName?: string;
  lastName: string;
  isHiddenFromIndividual: boolean;
  relationship: RelationshipType;
  otherRelationship?: string;
  emails?: Email[];
  phones?: Phone[];
  useIndividualAddress?: boolean;
  addresses?: Address[];
  isFullTimeStudent: boolean;
  dateOfBirth?: string;
  gender?: GenderType;
  ssn?: string;
  externalDependentId?: string;
  groupNumber?: string;
  memberId?: string;
  medicareId?: string;
  entitlementReason?: EntitlementReasonType;
  apiConnexId?: string;
  isSmoker?: boolean;
  fullName?: string;
}

export interface EligibilityClass {
  classId: number;
  eligibilityEffectiveDate: EligibilityEffectiveDateType;
  name: string;
  waitingPeriod: number;
}

export interface Email extends EntityBase {
  email: string;
  isVerified: boolean;
}

export interface FinancialInstitution extends EntityBase {
  name: string;
}

export interface Individual extends EntityBase {
  externalId?: string;
  convertedFromDependentId?: string;
  fullName?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  nickname?: string;
  addresses: Address[];
  primaryEmail?: string;
  firstAlternateEmail?: string;
  primaryPhone?: string;
  firstAlternatePhone?: string;
  emails?: Email[];
  phones: Phone[];
  dateOfBirth?: string;
  dateOfDeath?: string;
  gender?: GenderType;
  specifiedGender?: string;
  pronouns?: PronounsType;
  specifiedPronouns?: string;
  showPronounsToEmployer?: boolean;
  iabAccountId?: string;
  advanceAccountId?: string;
  groupAccountId?: string;
  socialSecurityNumber?: string;
  lastFourOfSSN?: string;
  individualInvestmentAccountId?: string;
  individualInvestmentAccountSSN?: string;
  individualInvestmentAccountSSOId?: string;
  idVerification?: IdVerificationType;
  pwResetAttemptCounter?: number;
  medicareId?: string;
  entitlementReason?: EntitlementReasonType;
  apiConnexId?: string;
  isSmoker?: boolean;
  emailAddressChangedDate?: string;
}

export interface Instance extends EntityBase {
  name: string;
  cardGroupVendor?: string;
  cardSingleVendor?: string;
  nonStandardVendor?: string;
  cmsReportingId?: string;
  cmsReportingStartDate?: string;
  cmsReportingMedicalRequestTypes?: RequestTypes;
  cmsReportingRxRequestTypes?: RequestTypes;
  eligibilityEventDefinitions?: EligibilityEventDefinition[];
  eligibilityModificationEvents?: EligibilityModificationEvent[];
  convenienceFeeCalculationDefinitions?: ConvenienceFeeCalculationDefinition[];
  disableSupportRequest?: boolean;
}

export type IndividualPasscode = string;

export interface CalculateEditableEventsResponse {
  eligibilityEventDefinitions?: EligibilityEventDefinition[];
  eligibilityModificationEvents: EligibilityModificationEvent[];
}

export interface VerifyIndividualPasscodeResponse {
  type: VerifyIndividualPasscodeResponseType;
}

export interface Charity extends EntityBase {
  name: string;
  isDefunctOrMerged: boolean;
  isInGoodStandingWithIRS?: boolean;
  networkId?: string;
  ein: string;
  type: CharityType;
  network: CharityNetwork;
  subsectionCode?: CharitySubsectionCode;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  paymentAddressLine1?: string;
  paymentAddressLine2?: string;
  paymentCity?: string;
  paymentState?: string;
  paymentZip?: string;
  paymentCountry?: string;
  category?: string;
  expenseProgramServices?: number;
  expenseAdministration?: number;
  expenseFundraising?: number;
  totalRevenue?: number;
  totalExpenses?: number;
  totalAssets?: number;
  disbursementMethod: DisbursementMethod;
  disbursementDestination?: DisbursementDestination;
  disbursementFrequency: DisbursementFrequency;
  isAccountInitialized: boolean;
}

export interface Phone extends EntityBase {
  phone?: string;
  ext?: string;
  phoneType?: PhoneType;
  isSmsCapable?: boolean;
  isVerified: boolean;
}

export interface Vendor extends EntityBase {
  name: string;
}

export interface ForgotPasswordRequest extends EntityBase {
  userEntityType: ForgotPasswordRequestUserEntityType;
  email: string;
  lastFourOfSSN: string;
}

export interface ClientIdentityProvider extends EntityBase {
  identityProvider: string;
  description?: string;
  appType: AppType;
  idpOverrides?: object;
}

export interface LoginInfo {
  loginType: LoginType;
  identityProvider?: string;
  description?: string;
  idpOverrides?: object;
}

export interface ClientSummaryDashboardInfo {
  jiraTickets: Ticket[];
  fileBoundTickets: Ticket[];
  clientDashboardInfo: ClientDashboardInfo;
}

export interface Ticket {
  key: string;
  link: string;
  created: string;
  title: string;
  status: string;
  stakeholderName: string;
  stakeholderAvatarUrl?: string;
}

export interface ClientDashboardInfo {
  accountManager?: string;
  externalId: string;
  location?: string;
}

export interface EligibilityEventProfile extends EntityBase {
  profileId: string;
  profileType: ProfileType;
  profileState: ProfileState;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth?: string;
  gender?: GenderType;
  relationship?: RelationshipType;
  isSmoker?: boolean;
  retired?: boolean;
  primaryAddress?: Address;
  clientEligibilityClassId?: number;
}

export interface RequestType {
  expenseType?: string;
  serviceType?: string;
}

export interface EligibilityEventDefinition {
  name: string;
  description?: string;
  code?: string;
}

export interface ConvenienceFeeCalculationDefinition {
  paymentMethodType: ConvenienceFeePaymentType;
  calculationType: ConvenienceFeeCalculationType;
  amount: number;
}

export interface EligibilityEvent {
  name: string;
  description?: string;
  code?: string;
}

export interface EligibilityModificationEvent {
  name: string;
  code?: string;
  description?: string;
}

export interface ClientFinanceValues {
  externalId: string;
  clientStatus?: string;
  activeDeclaredValueRenewRR?: number;
  pendingCancellationDeclaredValueRenewRR?: number;
  cancelledDeclaredValueRenewRR?: number;
  declaredValueRenewBLE?: number;
  estInterchange?: number;
  annualCardSpend?: number;
  estInterest?: number;
  interestVolume?: number;
  estAum?: number;
  aumVolume?: number;
  estCash?: number;
  netCashPosition?: number;
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface TrackingStatusCriteria {
  key?: TrackingStatusSearchCriteriaAllowedKeys;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export type TrackingStatusSearchCriteria = TrackingStatusCriteria[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface ClientArgs {
  jobTrackingId?: string;
  offeringId?: string;
  pricingModelIds?: string[];
}

export interface IndividualArgs {
  clientId?: string;
  employmentTerminationDate?: string;
}

export interface BenefitTerminationInfo {
  benefitAccountId: string;
  eligibilityEndDate?: string;
  lastPayrollDate: string;
  fullCoveragePaidThroughDate?: string;
}

export interface VerifyIndividualPasscodeRequest {
  tascId: string;
  passcode: string;
  email: string;
}

export type Addresses = Address[];

export type BankAccounts = BankAccount[];

export type Bureaus = Bureau[];

export type Payees = Payee[];

export type Clients = Client[];

export interface ClientActions {
  editMaxAdvanceAmount: boolean;
  disableAdvance: boolean;
  disableCard: boolean;
  planActions: PlanActions[];
}

export type ClientIdentityProviders = ClientIdentityProvider[];

export type Distributors = Distributor[];

export type Contacts = Contact[];

export type Dependents = Dependent[];

export type EligibilityClasses = EligibilityClass[];

export type FinancialInstitutions = FinancialInstitution[];

export type Individuals = Individual[];

export type Instances = Instance[];

export type Charities = Charity[];

export type Vendors = Vendor[];

export type ClientHoldAudits = ClientHoldAudit[];

export type RequestTypes = RequestType[];

export interface ClientAccountBalance {
  balance?: number;
}

export type ClientTrackingStatus = IndividualTrackingStatus[];

export interface IndividualTrackingStatus {
  individualId?: string;
  externalId: string;
  employeeId?: string;
  designation?: string;
  lastName?: string;
  firstName?: string;
  status: IndividualTrackingStatusType;
  statusReason: TrackingStatusReason;
  exemptReason?: string;
  lastTrackingEvent?: string;
  compliantOn?: string;
  lastTestedPositive?: string;
  history: TrackingSubmission[];
}

export interface TrackingSubmission {
  submissionType: TrackingSubmissionType;
  state: TrackingSubmissionState;
  exemptReason?: string;
  vaccine?: string;
  testResult?: TrackingTestResult;
  testingReason?: string;
  trackingDate?: string;
  reported: string;
  proofUrl?: string;
}

export interface CommandBase {
  id?: string;
  eventCorrelationId?: string;
  jobId?: string;
  producerId?: string;
  sequenceId?: number;
  version?: number;
  type?: string;
  created?: string;
  createdBy?: string;
  createdById?: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export enum BureauCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToPendingInactivation = 'ActiveToPendingInactivation',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToInactive = 'OnHoldToInactive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  PendingInactiveToActive = 'PendingInactiveToActive',
  PendingInactivationToInactive = 'PendingInactivationToInactive',
  PendingInactiveToPendingInactive = 'PendingInactiveToPendingInactive',
  StartToActive = 'StartToActive',
}

export enum ClientCommandType {
  AnyToPropagatingPriceChanges = 'AnyToPropagatingPriceChanges',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToErrorXFAuthFailure = 'ActiveToErrorXFAuthFailure',
  ActiveToErrorXFDuplicate = 'ActiveToErrorXFDuplicate',
  ActiveToErrorXFRetry = 'ActiveToErrorXFRetry',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToPendingInactivation = 'ActiveToPendingInactivation',
  ActiveToRemoved = 'ActiveToRemoved',
  ConversionToActive = 'ConversionToActive',
  ConversionToConversion = 'ConversionToConversion',
  ConversionToErrorXFAuthFailure = 'ConversionToErrorXFAuthFailure',
  ConversionToErrorXFDuplicate = 'ConversionToErrorXFDuplicate',
  ConversionToErrorXFRetry = 'ConversionToErrorXFRetry',
  ConversionToRemoved = 'ConversionToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToInactive = 'OnHoldToInactive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  OnHoldToPendingInactivation = 'OnHoldToPendingInactivation',
  PendingInactivationToActive = 'PendingInactivationToActive',
  PendingInactivationToInactive = 'PendingInactivationToInactive',
  PendingInactivationToOnHold = 'PendingInactivationToOnHold',
  PendingInactivationToPendingInactivation = 'PendingInactivationToPendingInactivation',
  PendingXFToConversion = 'PendingXFToConversion',
  PendingXFToErrorXFAuthFailure = 'PendingXFToErrorXFAuthFailure',
  PendingXFToErrorXFDuplicate = 'PendingXFToErrorXFDuplicate',
  PendingXFToErrorXFRetry = 'PendingXFToErrorXFRetry',
  ProcessPendingHoldToRemoveHold = 'ProcessPendingHoldToRemoveHold',
  StartToActive = 'StartToActive',
  StartToConversion = 'StartToConversion',
  StartToError = 'StartToError',
  UpgradeToConversion = 'UpgradeToConversion',
  UpgradeToPendingXF = 'UpgradeToPendingXF',
}

export enum DistributorCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
}

export enum ContactCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
  StartToActive = 'StartToActive',
  UpgradeToActive = 'UpgradeToActive',
}

export enum DependentCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToDeceased = 'ActiveToDeceased',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToRemoved = 'ActiveToRemoved',
  DeceasedToActive = 'DeceasedToActive',
  DeceasedToDeceased = 'DeceasedToDeceased',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  RemovedToActive = 'RemovedToActive',
  StartToActive = 'StartToActive',
  StartToCreated = 'StartToCreated',
  StartToError = 'StartToError',
  CreatedToActive = 'CreatedToActive',
  CreatedToErrorXFAuthFailure = 'CreatedToErrorXFAuthFailure',
  CreatedToErrorXFDuplicate = 'CreatedToErrorXFDuplicate',
  CreatedToErrorXFRetry = 'CreatedToErrorXFRetry',
}

export enum FinancialInstitutionCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToSunsetting = 'ActiveToSunsetting',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  StartToActive = 'StartToActive',
  SunsettingToInactive = 'SunsettingToInactive',
  SunsettingToSunsetting = 'SunsettingToSunsetting',
}

export enum IndividualCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToDeceased = 'ActiveToDeceased',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  ActiveToRemoved = 'ActiveToRemoved',
  DeceasedToActive = 'DeceasedToActive',
  DeceasedToDeceased = 'DeceasedToDeceased',
  DeceasedToInactive = 'DeceasedToInactive',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
  OnHoldToActive = 'OnHoldToActive',
  StartToActive = 'StartToActive',
  StartToProcessing = 'StartToProcessing',
  StartToError = 'StartToError',
  StartToCreated = 'StartToCreated',
  CreatedToActive = 'CreatedToActive',
  CreatedToErrorXFAuthFailure = 'CreatedToErrorXFAuthFailure',
  CreatedToErrorXFDuplicate = 'CreatedToErrorXFDuplicate',
  CreatedToErrorXFRetry = 'CreatedToErrorXFRetry',
  ProcessingToActive = 'ProcessingToActive',
  ProcessingToError = 'ProcessingToError',
}

export enum InstanceCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToInactive = 'OnHoldToInactive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  StartToActive = 'StartToActive',
}

export enum CharityCommandType {
  StartToActive = 'StartToActive',
  StartToInactive = 'StartToInactive',
  StartToOnHold = 'StartToOnHold',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToOnHold = 'InactiveToOnHold',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToInactive = 'OnHoldToInactive',
  OnHoldToOnHold = 'OnHoldToOnHold',
}

export enum VendorCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToOnHold = 'ActiveToOnHold',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  OnHoldToActive = 'OnHoldToActive',
  OnHoldToOnHold = 'OnHoldToOnHold',
  StartToActive = 'StartToActive',
}

export enum ForgotPasswordRequestCommandType {
  StartToSent = 'StartToSent',
}

export enum ClientIdentityProviderCommandType {
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToActive = 'InactiveToActive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToRemoved = 'InactiveToRemoved',
  StartToActive = 'StartToActive',
}

export enum ClientHoldAuditCommandType {
  StartToActive = 'StartToActive',
}

export enum PayeeCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToActive = 'InactiveToActive',
  ActiveToPermanentlyInactive = 'ActiveToPermanentlyInactive',
  InactiveToPermanentlyInactive = 'InactiveToPermanentlyInactive',
}

export enum EligibilityEventProfileCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BureauState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
}

export enum PayeeState {
  Active = 'Active',
  Inactive = 'Inactive',
  PermanentlyInactive = 'PermanentlyInactive',
}

export enum ClientState {
  Active = 'Active',
  Conversion = 'Conversion',
  Error = 'Error',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  PendingInactivation = 'PendingInactivation',
  PendingXF = 'PendingXF',
  Removed = 'Removed',
  Start = 'Start',
  Upgrade = 'Upgrade',
}

export enum DistributorState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum ContactState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Start = 'Start',
}

export enum DependentState {
  Active = 'Active',
  Deceased = 'Deceased',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
  Created = 'Created',
  Error = 'Error',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
}

export enum FinancialInstitutionState {
  Active = 'Active',
  Inactive = 'Inactive',
  Sunsetting = 'Sunsetting',
  Start = 'Start',
}

export enum IndividualState {
  Active = 'Active',
  Deceased = 'Deceased',
  Created = 'Created',
  Error = 'Error',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
  Processing = 'Processing',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
}

export enum InstanceState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Start = 'Start',
}

export enum CharityState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Start = 'Start',
}

export enum VendorState {
  Active = 'Active',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Start = 'Start',
}

export enum ForgotPasswordRequestState {
  Sent = 'Sent',
  Start = 'Start',
}

export enum ClientHoldAuditState {
  Active = 'Active',
  Start = 'Start',
}

export enum EligibilityEventProfileState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum ProfileState {
  Active = 'Active',
  Deceased = 'Deceased',
  Created = 'Created',
  Error = 'Error',
  Inactive = 'Inactive',
  OnHold = 'OnHold',
  Removed = 'Removed',
  Start = 'Start',
  ErrorXFAuthFailure = 'ErrorXFAuthFailure',
  ErrorXFDuplicate = 'ErrorXFDuplicate',
  ErrorXFRetry = 'ErrorXFRetry',
}

export enum BusinessFunctionType {
  Accounting = 'Accounting',
  Auditor = 'Auditor',
  Billing = 'Billing',
  ContractAdministration = 'ContractAdministration',
  Files = 'Files',
  Finance = 'Finance',
  HR = 'HR',
  Marketing = 'Marketing',
  Operations = 'Operations',
  Technology = 'Technology',
}

export enum FileType {
  Census = 'Census',
  Crossroads = 'Crossroads',
  Enrollment = 'Enrollment',
  PostingVerification = 'PostingVerification',
  COBRA = 'COBRA',
  ACAReporting = 'ACAReporting',
  Event = 'Event',
  GeneralInitialNotice = 'GeneralInitialNotice',
}

export enum TrackingStatusSearchCriteriaAllowedKeys {
  clientId = 'clientId',
  status = 'status',
  statusReason = 'statusReason',
  lastTrackingEvent = 'lastTrackingEvent',
  designation = 'designation',
}

export enum VerifyIndividualPasscodeResponseType {
  EmailAddressHasBeenAssociatedSuccessfully = 'EmailAddressHasBeenAssociatedSuccessfully',
  EmailAddressIsAlreadyAssociated = 'EmailAddressIsAlreadyAssociated',
  EmailAddressInUse = 'EmailAddressInUse',
  DifferentEmailAddressIsAlreadyAssociatedToAccount = 'DifferentEmailAddressIsAlreadyAssociatedToAccount',
}

export enum AddressOwnerType {
  External = 'External',
  Primary = 'Primary',
}

export enum CardType {
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
}

export enum PaymentAccountType {
  BankAccount = 'BankAccount',
  Check = 'Check',
  Wire = 'Wire',
  Card = 'Card',
  Trade = 'Trade',
}

export enum PaymentVendor {
  XFormative = 'XFormative',
  Braintree = 'Braintree',
}

export enum BankAccountType {
  BusinessChecking = 'BusinessChecking',
  BusinessSavings = 'BusinessSavings',
  GeneralLedger = 'GeneralLedger',
  PersonalChecking = 'PersonalChecking',
  PersonalSavings = 'PersonalSavings',
}

export enum ClientCardType {
  Standard = 'Standard',
  Custom = 'Custom',
}

export enum CardMailingOptionType {
  Participant = 'Participant',
}

export enum CardSecondLineType {
  ParticipantTASCID = 'ParticipantTASCID',
}

export enum ParentType {
  INSTANCE = 'INSTANCE',
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  PAYEE = 'PAYEE',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
}

export enum RelationshipType {
  Spouse = 'Spouse',
  Child = 'Child',
  DomesticPartner = 'DomesticPartner',
  ExSpouse = 'ExSpouse',
  SurvivingSpouse = 'SurvivingSpouse',
  Other = 'Other',
}

export enum PositionType {
  OwnerOfficer = 'OwnerOfficer',
  Executive = 'Executive',
  Director = 'Director',
  Manager = 'Manager',
  Other = 'Other',
  Sales = 'Sales',
  AccountManagement = 'AccountManagement',
  Consultant = 'Consultant',
  Finance = 'Finance',
  Management = 'Management',
}

export enum ContactType {
  Client = 'Client',
  Distributor = 'Distributor',
  Payee = 'Payee',
  Vendor = 'Vendor',
}

export enum AddressType {
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Other = 'Other',
}

export enum FilingStatusType {
  CCorp = 'CCorp',
  SCorp = 'SCorp',
  Partnership = 'Partnership',
  SoleProprietor = 'SoleProprietor',
  NonProfit = 'NonProfit',
  LLC = 'LLC',
  Other = 'Other',
}

export enum EntitlementReasonType {
  A = 'A',
  B = 'B',
  G = 'G',
}

export enum UserAccessRoleType {
  CU1 = 'CU1',
  CU2 = 'CU2',
  CU3 = 'CU3',
  CU4 = 'CU4',
  CU5 = 'CU5',
  CU6 = 'CU6',
  CU7 = 'CU7',
  CU8 = 'CU8',
}

export enum LoginType {
  Password = 'Password',
  SAML = 'SAML',
  UserNotFound = 'UserNotFound',
  IndividualNotActive = 'IndividualNotActive',
  ContactNotActive = 'ContactNotActive',
  ClientNotActive = 'ClientNotActive',
}

export enum AppType {
  CU = 'CU',
  PU = 'PU',
}

export enum ContactStateType {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum ContactRoleType {
  Billing = 'Billing',
  Overall = 'Overall',
  Daily = 'Daily',
  EDI = 'EDI',
  Eligibility = 'Eligibility',
  Conversion = 'Conversion',
  Other = 'Other',
}

export enum ClientIdentityProviderStateType {
  Start = 'Start',
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum CancellationReasonType {
  NLOBLackOfParticipation = 'NLOBLackOfParticipation',
  NLOBLackOfBenefit = 'NLOBLackOfBenefit',
  NLOBNoLongerQualifiesForThePlan = 'NLOBNoLongerQualifiesForThePlan',
  NLOBNoLongerInBusiness = 'NLOBNoLongerInBusiness',
  MTNAConsolidationOfServices = 'MTNAConsolidationOfServices',
  MTNAPrice = 'MTNAPrice',
  MTNADistributorBrokerRequest = 'MTNADistributorBrokerRequest',
  DWTCustomerService = 'DWTCustomerService',
  DWTPrice = 'DWTPrice',
  DWTDistributorBrokerRecord = 'DWTDistributorBrokerRecord',
  DWTTechnology = 'DWTTechnology',
  DWTOther = 'DWTOther',
  SAPPrice = 'SAPPrice',
  SAPChangeInPlanOfferings = 'SAPChangeInPlanOfferings',
  ICNonPayment = 'ICNonPayment',
  ICPlanNotEnacted = 'ICPlanNotEnacted',
  CancellationReasonUnknown = 'CancellationReasonUnknown',
  TascTermination = 'TascTermination',
  Other = 'Other',
}

export enum CardFeePaidByType {
  Participant = 'Participant',
  Client = 'Client',
}

export enum CustomizationPackageType {
  Standard = 'Standard',
  CoBrand = 'Co-Brand',
  Custom = 'Custom',
}

export enum ConvenienceFeePaymentType {
  Card = 'Card',
  ACH = 'ACH',
  Check = 'Check',
}

export enum ConvenienceFeeCalculationType {
  Percentage = 'Percentage',
  Flat = 'Flat',
}

export enum EligibilityEffectiveDateType {
  FirstDay = 'FirstDay',
  FirstOfMonth = 'FirstOfMonth',
  SameDay = 'SameDay',
}

export enum EmployerType {
  PEO = 'PEO',
  ASO = 'ASO',
  MEWA = 'MEWA',
  JointEmployer = 'JointEmployer',
  IntegratedEmployer = 'IntegratedEmployer',
  ControlledGroup = 'ControlledGroup',
  GovernmentalEntity = 'GovernmentalEntity',
  Municipality = 'Municipality',
}

export enum GenderType {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
  OptionNotListed = 'OptionNotListed',
  Unknown = 'Unknown',
}

export enum PayeeType {
  BrokerDistributor = 'BrokerDistributor',
  Carrier = 'Carrier',
  Custom = 'Custom',
}

export enum PronounsType {
  SheHerHers = 'SheHerHers',
  HeHimHis = 'HeHimHis',
  TheyThemTheirs = 'TheyThemTheirs',
  OptionNotListed = 'OptionNotListed',
  Unknown = 'Unknown',
}

export enum IdVerificationType {
  Pass = 'Pass',
  Pending = 'Pending',
  Fail = 'Fail',
}

export enum PhoneType {
  Mobile = 'Mobile',
  Home = 'Home',
  Work = 'Work',
  Other = 'Other',
}

export enum CharityType {
  Charity = 'Charity',
}

export enum CharityNetwork {
  GuideStar = 'GuideStar',
}

export enum CharitySubsectionCode {
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  C7 = 'C7',
  C8 = 'C8',
  C9 = 'C9',
  C10 = 'C10',
  C11 = 'C11',
  C12 = 'C12',
  C13 = 'C13',
  C14 = 'C14',
  C15 = 'C15',
  C16 = 'C16',
  C17 = 'C17',
  C18 = 'C18',
  C19 = 'C19',
  C20 = 'C20',
  C21 = 'C21',
  C22 = 'C22',
}

export enum DisbursementMethod {
  Check = 'Check',
}

export enum DisbursementDestination {
  PrimaryAddress = 'PrimaryAddress',
  PaymentAddress = 'PaymentAddress',
}

export enum DisbursementFrequency {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export enum ManualHoldReasons {
  Bankruptcy = 'Bankruptcy',
  Finance = 'Finance',
  OutstandingAdminFees = 'OutstandingAdminFees',
  OutstandingFundingInvoice = 'OutstandingFundingInvoice',
  ServiceAgreementCancelled = 'ServiceAgreementCancelled',
}

export enum SystemHoldReasons {
  OutstandingFundingPVR = 'OutstandingFundingPVR',
}

export enum HoldExceptionReasons {
  PaymentInRoute = 'PaymentInRoute',
  ServiceIssue = 'ServiceIssue',
  Tenuous = 'Tenuous',
  ACQNew = 'ACQNew',
  ListBillProvider = 'ListBillProvider',
  FundingViaCheck = 'FundingViaCheck',
  FundingPaymentBuffer = 'FundingPaymentBuffer',
  ExecutiveApproved = 'ExecutiveApproved',
}

export enum ClientHoldAuditActivity {
  ManualHoldSet = 'ManualHoldSet',
  ManualHoldRemoved = 'ManualHoldRemoved',
  SystemHoldSet = 'SystemHoldSet',
  SystemHoldExpired = 'SystemHoldExpired',
  HoldExceptionSet = 'HoldExceptionSet',
  HoldExceptionExpired = 'HoldExceptionExpired',
}

export enum ClientHoldAuditReason {
  Bankruptcy = 'Bankruptcy',
  Finance = 'Finance',
  OutstandingAdminFees = 'OutstandingAdminFees',
  OutstandingFundingInvoice = 'OutstandingFundingInvoice',
  ServiceAgreementCancelled = 'ServiceAgreementCancelled',
  OutstandingFundingPVR = 'OutstandingFundingPVR',
  PaymentInRoute = 'PaymentInRoute',
  ServiceIssue = 'ServiceIssue',
  Tenuous = 'Tenuous',
  ACQNew = 'ACQNew',
  ListBillProvider = 'ListBillProvider',
  FundingViaCheck = 'FundingViaCheck',
  FundingPaymentBuffer = 'FundingPaymentBuffer',
  ExecutiveApproved = 'ExecutiveApproved',
}

export enum ForgotPasswordRequestUserEntityType {
  CONTACT = 'CONTACT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum TPAPartnerType {
  BASIC = 'BASIC',
  TASC = 'TASC',
}

export enum IndividualTrackingStatusType {
  RequireProof = 'RequireProof',
  Submitted = 'Submitted',
  VerifiedCompliant = 'VerifiedCompliant',
  Testing = 'Testing',
}

export enum TrackingStatusReason {
  NotReported = 'NotReported',
  InvalidDocumentation = 'InvalidDocumentation',
  InReview = 'InReview',
  FullyVaccinated = 'FullyVaccinated',
  PartiallyVaccinated = 'PartiallyVaccinated',
  TestedNegative = 'TestedNegative',
  TestedPositive = 'TestedPositive',
  Exempt = 'Exempt',
  TestingOverdue = 'TestingOverdue',
  Indeterminate = 'Indeterminate',
  Expired = 'Expired',
}

export enum TrackingSubmissionType {
  Vaccination = 'Vaccination',
  TestResult = 'TestResult',
  ExemptionClaim = 'ExemptionClaim',
  ClientRequiresTesting = 'ClientRequiresTesting',
}

export enum TrackingTestResult {
  Negative = 'Negative',
  Positive = 'Positive',
}

export enum TrackingSubmissionState {
  Submitted = 'Submitted',
  Processed = 'Processed',
  Denied = 'Denied',
}

export enum ProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
}

export enum CommunicationPackageType {
  CobraCommunications = 'CobraCommunications',
  CommonwealthOfMa = 'CommonwealthOfMa',
  RetireeBillingCommunications = 'RetireeBillingCommunications',
  Conversion = 'Conversion',
  ElectionPaymentReminder = 'ElectionPaymentReminder',
  FinalPaymentReminder = 'FinalPaymentReminder',
  GeneralInitialNotice = 'GeneralInitialNotice',
  NormalPaymentReminder = 'NormalPaymentReminder',
  Pre65 = 'Pre65',
}

export enum ClientAccountType {
  CRA = 'CRA',
  CAB = 'CAB',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}
