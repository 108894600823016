<footer>
  <div class="container">
    <div class="page-footer text-center col-12 col-lg-12 px-0 pb-4 pb-lg-5 pt-4">
      <ng-container *ngIf="!brand.hideCopyright">
        <p class="mb-1">&copy; Copyright {{currentYear}} {{brand.companyNameCopyright}}. All rights reserved.</p>
      </ng-container>
      <ng-container *ngIf="!brand.hideSitemapLink">
        <p class="mb-0">V{{version}} | <a  class="btn-link" routerLink="/sitemap">Site Map</a></p>
      </ng-container>
    </div>
  </div>
</footer>
