<div class="enter-authentication pb-5">
  <div class="verification-code mb-4">Enter authentication code </div>
  <div class="sent-code">
    We just sent a code to {{phoneNumber}}.
  </div>
  <div class="mfa-fields">
    <form name="codeVerificationForm" [formGroup]="codeVerificationForm" (ngSubmit)="submitVerificationCode()">
      <app-textbox label="Enter 6-digit code" [showIconWhenValid]="true">
        <input data-cy="verification-code-input" class="form-control" type="tel" formControlName="verificationCode" placeholder="Enter Code" maxlength="6" minlength="6" />
      </app-textbox>
      <div class="form-group">
        <button type="button" class="no-border-btn btn-success-text" (click)="resendVerificationCode()">Resend code</button>
      </div>
      <div class="form-group d-flex pt-3 pb-1">
        <button class="btn btn-outline-secondary flex-fill" routerLink="/logout" type="button">Cancel</button>
        <button data-cy="verification-code-submit" class="btn btn-primary flex-fill" type="submit" [ngClass]="{ 'btn-disabled btn-light': !codeVerificationForm.valid }" [disabled]="!codeVerificationForm.valid">
          Verify
        </button>
      </div>
    </form>
  </div>
</div>
