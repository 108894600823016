<div class="viewport-container">
  <div class="d-flex justify-content-between">
    <img *ngIf="brandId === BrandId.TASC" class="mb-3" src="assets/images/{{brand.logoFileName}}" [alt]="brand.companyName">
    <button type="button" class="print-terms" (click)="printTermsOfUse()">
      <span aria-hidden="true">
        <div class="icon icon-print text-green-color"></div>
      </span>
      <span class="sr-only">Print Terms of Use</span>
    </button>
  </div>
  <div class="row grow">
    <div class="col-12">
      <div data-cy="terms-of-use-document" class="iframe-container embed-responsive">
        <iframe data-cy="tou-body" #termsIframe [src]="iframeUrl | safeUrl" title="Terms of Use" class="embed-responsive-item"></iframe>
      </div>
    </div>
  </div>
  <div class="row py-4">
    <div class="col-12 col-lg-6 offset-lg-3 text-center">
      The information contained in this communication is confidential and to be used by {{brand.productName}} users
      for its intended purpose.
      <br />
      &copy;{{brand.companyName}}
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-4 offset-lg-4">
      <button type="button" class="btn btn-primary btn-block" (click)="signTermsOfUse()" data-cy="terms-of-use-agree">Agree</button>
    </div>
  </div>
</div>
