import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PipesModule } from '../shared/pipes/pipes.module';
import { AlertComponent } from './components/alert/alert.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderWorkflowComponent } from './components/app-header-workflow/app-header-workflow.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { SignUpFooterComponent } from './components/sign-up-footer/sign-up-footer.component';

@NgModule({
  declarations: [
    AlertComponent,
    AppHeaderComponent,
    AppHeaderWorkflowComponent,
    AppFooterComponent,
    SignUpFooterComponent,
  ],
  exports: [
    AlertComponent,
    AppHeaderComponent,
    AppHeaderWorkflowComponent,
    AppFooterComponent,
    SignUpFooterComponent,
  ],
  imports: [
    BsDropdownModule,
    CollapseModule,
    CommonModule,
    PipesModule,
    RouterModule,
  ],
})
export class MarginalsModule { }
