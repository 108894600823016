import { BrandResource } from 'src/app/shared/models/pux/model';

export const crossroadsResources: BrandResource = {
  generalAccountText: 'account',
  generalPlanText: 'plan',
  benefitAccountText: 'account',
  benefitPlanText: 'account',
  benefitText: 'account',
  cardLegalText: 'This Mastercard is administered by TASC, a registered agent of Pathward®, N.A. Use of this card is authorized as set forth in your Cardholder Agreement. The TASC Card is issued by Pathward, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated.',
  cardLink: 'Wallet',
  companyCard: 'Crossroads card',
  companyFullName: 'Crossroads Health',
  companyName: 'Crossroads',
  companyNameCopyright: 'Total Administrative Services Corporation (TASC)',
  contactHours: 'Monday - Friday, 8a.m. - 5p.m.<br>based on your timezone.',
  brandCssClass: 'brand-crossroads',
  faviconFileName: 'favicon-crossroads.ico',
  hideBenefitAccountSummary: true,
  hideCardLostOrStolen: true,
  hideCardPINSetup: true,
  hideDashboard: true,
  hideDependents: true,
  hideEnrollment: true,
  hideNewCardRequest: true,
  hideSupport: true,
  hideTransactionsSubnav: true,
  hideWalletWithoutCard: true,
  hideSettingsLink: true,
  hideAlertsLink: true,
  hideAccountsTab: true,
  hideCopyright: true,
  hideSitemapLink: true,
  logoFileName: 'logo-crossroads.png',
  participantId: 'Individual ID',
  phoneNumber: '800-422-4661',
  productName: 'Right Refund Account',
  showPayoutWorkflow: true,
  termsOfUseFileName: 'terms-crossroads.html',
  achTransferDisclaimerFileName: 'ach-disclaimer.pdf',
  mastercardSendDisclaimerFileName: 'mastercard-send-disclaimer.pdf',
  virtualCardDisclaimerFileName: 'crossroads-digital-card-ToU.pdf',
  homePageOverride: '/dashboard/refund',
};
