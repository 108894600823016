<app-modal size="small" #modal data-cy="app-federated-password-user-null-modal">
  <div class="modal-content text-center">
    <div class="modal-header">
      <i class="icon icon-process warning-icon" aria-hidden="true"></i>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <i class="icon icon-close" aria-hidden="true"></i>
      </button>
    </div>
    <div class="modal-body">
      <h1 class="font-light weight mb-4 default lh-40">
        Please sign in through your employer portal, or sign up with this email address to create your password.
      </h1>
    </div>
    <div class="pt-3 modal-footer">
      <div class="d-flex col-sm-12 col-xs-12 px-0">
        <button name="employeePortal" type="button" (click)="redirectToFederatedSignInPage()"
          class="btn btn-block my-1 btn-primary">{{ buttonLabel }}</button>
      </div>
      <div class="d-flex col-sm-12 col-xs-12 px-0 mt-3">
        <button name="signUp" type="button" aria-label="Close" routerLink="/signup"
          class="btn btn-block my-1 btn-outline-secondary" data-cy="contiue-with-sign-up">Sign up</button>
      </div>
    </div>
  </div>
</app-modal>