<app-modal size="large">
  <div data-cy="terms-of-use-modal" class="modal-content">
    <div class="modal-body">
      <div id="print-section" class="d-flex flex-column">
        <div class="d-flex justify-content-between pt-4">
          <button type="button" class="print-terms" (click)="printTermsOfUse()">
            <span aria-hidden="true">
              <div class="icon icon-print text-green-color"></div>
            </span>
            <span class="sr-only">Print Terms of Use</span>
          </button>
          <img *ngIf="brandId === BrandId.TASC" src="assets/images/{{brand.logoFileName}}" [alt]="brand.companyName">
          <button type="button" class="close-terms" aria-label="Close" (click)="close()" data-cy="close-tou-button">
            <span aria-hidden="true">
              <div class="icon icon-close"></div>
            </span>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="iframe-container embed-responsive flex-grow-1">
          <iframe #termsIframe [src]="iframeUrl | safeUrl" title="Terms of Use" class="embed-responsive-item" data-cy="tou-body"></iframe>
        </div>
      </div>
    </div>
  </div>
</app-modal>
