<div class="login-fields pt-lg-4 pt-3">
  <!-- Main Form Start -->
  <form name="loginForm" [formGroup]="loginForm" [appDisableAfterSubmit]="enableLoginForm" (ngSubmit)="submitLogin()">
    <div class="form-group input-group" *ngIf="authenticationChallenge.challengeType !== AuthenticationChallengeType.Password">
      <h2 class="login-form-title login-form-title-email">Enter email address</h2>
      <app-textbox label="Email" class="w-100">
        <input id="username" type="email" class="form-control" autofocus formControlName="email" placeholder="Email" required data-cy="login-email-input" />
      </app-textbox>
      <!-- This input field is needed for password managers -->
      <input id="password" type="password" style="display: none;" />
    </div>
    <div class="form-group input-group" *ngIf="authenticationChallenge.challengeType === AuthenticationChallengeType.Password">
      <button id="btn-back" type="button" class="btn btn-link btn-small back-button" (click)="back()" data-cy="login-back">
        <i class="icon icon-arrow-bone-left back-button-icon" aria-hidden="true"></i>
        <span class="back-button-span">Back</span>
      </button>
      <h2 class="login-form-title">Enter password</h2>
      <app-password-textbox label="Password" class="w-100">
        <input id="password" type="password" class="form-control" formControlName="password" placeholder="Password" required data-cy="login-password-input" />
      </app-password-textbox>
    </div>
    <div class="pt-2 pt-lg-4">
      <a class="btn-link font-regular" routerLink="/forgot-password" data-cy="forgot-password-link">Forgot password?</a>
    </div>
    <div class="form-group input-group pt-3 pb-4 remember-me">
      <div class="d-flex flex-wrap justify-content-between align-items-center w-100 px-0">
        <div data-cy="remember-switch" class="remember-switch">
          <label class="switch d-flex align-items-center">
            <input type="checkbox" formControlName="rememberMe" />
            <span class="slider round mr-3"></span>
            <span>Remember me</span>
          </label>
        </div>
        <div class="px-0">
          <button type="submit" name="submitFormBtn" class="btn btn-block my-1 btn-primary"
            [ngClass]="{ 'btn-disabled btn-light' : !loginForm.valid }" [disabled]="!loginForm.valid" data-cy="login-sign-in">
            {{ authenticationChallenge.challengeType === AuthenticationChallengeType.Unknown ? "Next" : "Sign in" }}
          </button>
        </div>
      </div>
    </div>
  </form>

  <app-federated-redirect-modal #federatedRedirectModal (modalClose)="setUserAuthenticationChallenge($event)"></app-federated-redirect-modal>
  <app-federated-password-user-null-modal #federatedPasswordUserNullModal></app-federated-password-user-null-modal>

  <!-- Main Form End -->
</div>
