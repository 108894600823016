import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupportRequestDocumentStore } from '@app/state/support-request/support-request-document.store';
import { cacheable, withTransaction } from '@datorama/akita';
import { Attachment } from '@models/file/model';
import {
  Document,
  DocumentCategoryType,
  DocumentState,
  ParentType as ProfileConfigurationParentType,
} from '@models/profileConfiguration/model';
import { maxBy } from 'lodash';
import { forkJoin, Observable, of, zip } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/models/pux/enum';
import { SearchQuery } from 'src/app/shared/models/pux/model';
import { SupportRequestDetailStore } from 'src/app/state/support-request-detail/support-request-detail.store';
import {
  ChainType,
  CreateSupportRequestCommand,
  MatchType,
  SearchCriteria,
  SupportRequest,
  SupportRequestCommandType,
  SupportRequestComments,
  SupportRequestCommentsCommand,
  SupportRequestDetail,
  SupportRequestResponse,
  SupportRequestSearchFilters,
} from '../../shared/models/uba/support/model';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { Dates } from '../../shared/utils/dates';
import { FileUploadService } from '../../shared/utils/fileUpload.service';
import { cacheableById, IndividualQuery, SupportRequestQuery, SupportRequestStore } from '../../state';
import { Uri } from './../../shared/services/uri';
import { CommandFactory } from './../../shared/utils/command.factory';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestService {

  public constructor(
    private readonly fileUploadService: FileUploadService,
    private commandFactory: CommandFactory,
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private readonly supportRequestStore: SupportRequestStore,
    private readonly supportRequestDetailStore: SupportRequestDetailStore,
    private readonly supportRequestDocumentStore: SupportRequestDocumentStore,
    private readonly supportRequestQuery: SupportRequestQuery,
  ) { }

  /**
   * Retrieve all Support Requests
   */
  public getSupportRequests(
    orderDirection: 'DESC' | 'ASC' = 'DESC',
    updatedFrom: string = Dates.subtractYears(Dates.today(), 1),
    updatedTo?: string,
    options: { bustCache: boolean } = {
      bustCache: false,
    },
  ): Observable<SupportRequest[]> {
    const individual = this.individualQuery.getActive();
    const query: SearchQuery = {
      orderDirection,
    };
    const searchCriteria: SupportRequestSearchFilters = {
      updatedFrom,
    };
    if (updatedTo) {
      searchCriteria.updatedTo = updatedTo;
    }
    if (options.bustCache) {
      this.supportRequestStore.reset();
      this.supportRequestStore.setHasCache(false);
    }
    return this.supportRequestQuery.selectHasCache().pipe(
      switchMap((hasCache) => {
        if (hasCache) {
          return this.supportRequestQuery.selectAll();
        }
        const url = new Uri(`/profile/${individual.id}/supportRequest/search`, CoreService.Support, query);
        const request$ = this.http.post<SupportRequestResponse>(url.toString(), searchCriteria)
          .pipe(
            map((supportRequestResponse) => supportRequestResponse.data),
            withTransaction((supportRequests) => {
              this.supportRequestStore.upsertMany(supportRequests);
              this.supportRequestStore.setHasCache(true);
            }),
          );

        return cacheableById(JSON.stringify(searchCriteria) + JSON.stringify(query), this.supportRequestStore, request$, { emitNext: true });
      }),
    );
  }

  /**
   * Saves a new support request, with attachments, in FileBound
   */
  public submitSupportRequest(supportRequest: SupportRequest, files?: File[]): Observable<SupportRequest[]> {
    return this.saveFileAttachments(files).pipe(
      switchMap((attachments) => this.saveSupportRequest(supportRequest, attachments)),
    );
  }

  /**
   * Get support request Details
   * client/1547-8520-7584/participant/5100-0126-3301/dependent/Ken Jones
   * client/1547-8520-7584/participant/5100-0126-3301
   */
  public getRequestDetails(supportRequestId: string): Observable<SupportRequestDetail> {
    const individual = this.individualQuery.getActive();
    const url = new Uri(`/profile/${individual.id}/supportRequest/${supportRequestId}/detail`, CoreService.Support);
    return this.http.get<SupportRequestDetail>(url.toString())
    .pipe(
      withTransaction(((data) => {
        data['isOnBehalfOfDependent'] = !!data.relatedProfiles?.dependent?.name;
        this.supportRequestDetailStore.upsert(supportRequestId, data);
      }),
      ), catchError(this.errorHandlingService.requestDetailsResponse()),
    );
  }

  public getDownloadFileUrl(supportRequestId: number, fileId: number): Observable<string> {
    const individual = this.individualQuery.getActive();
    const url = new Uri(`/profile/${individual.id}/supportRequest/${supportRequestId}/attachment/${fileId}`, CoreService.Support);
    const headers = { Accept: 'image/jpeg, application/octet-stream, */*' };
    return this.http.get<string>(url.toString(), { headers, responseType: 'blob' as 'json' })
      .pipe(catchError(this.errorHandlingService.supportRequestAttachment()));
  }

  /**
   * @returns {SupportRequestComments}
   * @memberof SupportRequestService
   */
     public createReplyRequest(): SupportRequestComments {
      return {
        commentText: '',
        attachments: [],
      };
    }

  /**
   * @param {SupportRequestComments} SupportRequestComments
   * @param {number} supportRequestId
   * @param {Files[]} files
   * @param {string} profileId
   * @returns
   * @memberof SupportRequestService
   */
   public updateComment(supportRequestComments: SupportRequestComments, id: number, profileId: string, files?: File[]): Observable<SupportRequestCommentsCommand> {
    if (files && files.length > 0) {
      const supportReqAttachments: Array<Observable<Attachment>> = files.map(
        (file) => {
          const attachment: Attachment = {
            attachmentType: file.name.substr(file.name.lastIndexOf('.') + 1),
            friendlyFileName: file.name.substr(0, file.name.lastIndexOf('.')),
          };
          return this.fileUploadService.uploadFile(file, attachment);
        });
      return forkJoin(supportReqAttachments).pipe(
        switchMap((attachments) => {
          return this.commentAttachments(
            profileId,
            supportRequestComments.commentText,
            id,
            attachments,
          );
        }),
        catchError(this.errorHandlingService.fileUploadErrorHandler()),
      );
    } else {
      return this.commentAttachments(
        profileId,
        supportRequestComments.commentText,
        id,
        [],
      );
    }
  }

  public getSupportRequestInformationalDocument(): Observable<Document> {
    const supportRequestInformationDocumentSearchCriteria: SearchCriteria = [
      { key: 'parentType', value: ProfileConfigurationParentType.INSTANCE, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'currentState', value: DocumentState.Active, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'category', value: DocumentCategoryType.TASC, matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'effectiveDate', value: Dates.today(), matchType: MatchType.LESS_THAN_EQUAL, chainType: ChainType.AND },
      { key: 'documentType', value: 'SupportRequestInformationalPage', matchType: MatchType.EXACT, chainType: ChainType.AND },
      { key: 'name', value: 'PUX', matchType: MatchType.CONTAINS },
    ];
    const uri = new Uri(`/profile/${this.individualQuery.getActive().id}/configuration/document/search`, CoreService.ProfileConfiguration);
    const request$ = this.http.post<Document[]>(uri.toString(), supportRequestInformationDocumentSearchCriteria)
      .pipe(
        map((documents) => maxBy(documents, 'version')),
        withTransaction((document) => {
          this.supportRequestDocumentStore.set([document]);
          this.supportRequestDocumentStore.setActive(document.id);
        }),
      );
    return cacheable(this.supportRequestDocumentStore, request$, { emitNext: true });
  }

  /**
   * Uploads files attached to the support request to S3 and returns Attachment entities describing attachments
   */
  private saveFileAttachments(files: File[]): Observable<Attachment[]> {
    if (!files || !files.length) {
      return of([]);
    }

    const attachments = files.map((file) => {
      const supportRequestAttachment: Attachment = {
        attachmentType: file.name.substr(file.name.lastIndexOf('.') + 1),
        friendlyFileName: file.name,
      };
      return this.fileUploadService.uploadFile(file, supportRequestAttachment);
    });

    return zip(...attachments);
  }

  /**
   * Saves a new support request.
   */
  private saveSupportRequest(supportRequest: SupportRequest, attachments?: Attachment[]): Observable<SupportRequest[]> {
    const individualId = this.individualQuery.getActiveId();
    const commandType = SupportRequestCommandType.StartToSent;
    const url = new Uri(`/profile/${individualId}/supportRequest/create/${commandType}`, CoreService.Support);

    supportRequest.attachments = attachments.map((attachment) => ({
      filePath: attachment.filePath,
      attachmentType: attachment.attachmentType,
      friendlyFileName: attachment.friendlyFileName,
    }));

    const command = this.commandFactory.createCommand(supportRequest as unknown as CreateSupportRequestCommand, commandType, false);

    return this.http.put<void>(url.toString(), command)
      .pipe(
        switchMap(() => this.getSupportRequests(undefined, undefined, undefined, { bustCache: true })),
      );
  }

  /**
   * @param {string} profileId
   * @param {string} commentText
   * @param {string} supportRequestId
   * @returns {Observable<SupportRequestCommentsCommand>}
   * @memberof SupportRequestService
   */
  private commentAttachments(profileId: string, commentText: string, supportRequestId: number, fileInfo: Attachment[]): Observable<SupportRequestCommentsCommand> {
    const commandType = SupportRequestCommandType.StartToSent;
    const url = new Uri(`/profile/${profileId}/supportRequestComments/${supportRequestId}/comments/${commandType}`, CoreService.Support);
    const command = this.commandFactory.createReplyCommand(commentText, fileInfo);
    return this.http.put<SupportRequestCommentsCommand>(url.toString(), command)
      .pipe(
        withTransaction(() => {
          this.getRequestDetails(supportRequestId.toString()).pipe(first()).subscribe();
        }),
        catchError(this.errorHandlingService.supportRequestCommentErrorHandler()),
      );
  }
}
