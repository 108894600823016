<app-modal size="small" #modal>
  <div class="modal-content">
    <div class="modal-body text-center">
      <h3>Unsupported browser version</h3>
      <p>
        We noticed you're using an older version of your browser. For the best experience and optimal security,
        please update your browser to the latest version, or switch to one of the supported browsers below.
      </p>
      <table class="browser-icons">
        <caption class="sr-only">Supported browsers</caption>
        <tr>
          <td>
            <div class="browser-icon">
              <img src="assets/images/browser-icons/chrome.png" alt="Chrome" />
            </div>
            <div class="browser-name">Chrome</div>
          </td>
          <td>
            <div class="browser-icon">
              <img src="assets/images/browser-icons/edge.png" alt="Edge" />
            </div>
            <div class="browser-name">Edge</div>
          </td>
          <td>
            <div class="browser-icon">
              <img src="assets/images/browser-icons/safari.png" alt="Safari" />
            </div>
            <div class="browser-name">Safari</div>
          </td>
          <td>
            <div class="browser-icon">
              <img src="assets/images/browser-icons/firefox.png" alt="Firefox" />
            </div>
            <div class="browser-name">Firefox</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="modal-footer">
      <table class="buttons">
        <caption class="sr-only">Buttons</caption>
        <tr>
          <td>
            <button type="button" class="btn btn-primary" (click)="modal.close()">Got it</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</app-modal>
