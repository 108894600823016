import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';

import { DirectivesModule } from '../../directives/directives.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ErrorComponent } from './error/error.component';
import { ModalComponent } from './modal/modal.component';
import { PaginateComponent } from './paginate/paginate.component';
import { PasswordTextboxComponent } from './password-textbox/password-textbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SecretTextboxComponent } from './secret-textbox/secret-textbox.component';
import { StaticTextComponent } from './static-text/static-text.component';
import { TextboxComponent } from './textbox/textbox.component';

@NgModule({
  imports: [
    BsDropdownModule,
    CollapseModule,
    CommonModule,
    DirectivesModule,
    ModalModule,
    ReactiveFormsModule,
  ],
  exports: [
    CheckboxComponent,
    CollapsibleComponent,
    DropdownComponent,
    ErrorComponent,
    ModalComponent,
    PaginateComponent,
    PasswordTextboxComponent,
    RadioButtonComponent,
    SecretTextboxComponent,
    StaticTextComponent,
    TextboxComponent,
  ],
  declarations: [
    CheckboxComponent,
    CollapsibleComponent,
    DropdownComponent,
    ErrorComponent,
    ModalComponent,
    PaginateComponent,
    PasswordTextboxComponent,
    RadioButtonComponent,
    SecretTextboxComponent,
    StaticTextComponent,
    TextboxComponent,
  ],
})
export class FormControlsModule { }
